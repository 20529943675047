<template>
  <div class="login-wrap">
    <div class="login-top">
      <div class="login-top-l flex col-center">
        <div class="time2 mr10">{{ nowDate }} {{ week }}</div>
        <div class="time1">{{ time }}</div>
      </div>
      <div class="login-top-m">
        <div class="title fs32" :class="title.length > 15 ? 'title-m' : ''">
          {{ title }}
          <!-- <img src="@/assets/img/4.0/topHeader.png" class="w330 h12" v-if="shouback"> -->
        </div>
      </div>
      <div :class="['login-top-r', hasRightSlot && 'hasback']">
        <slot name="right"></slot>
      </div>
      <div v-if="shouback" @click="back" class="showback"></div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/filters";
export default {
  name: "top",
  props: {
    shouback: {
      type: Boolean,
      default: false,
    },
    hasRightSlot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      week: "",
      date: "",
      time: "",
      // shouback: false,
      title: "",
      nowDate: "",
      week: "",
    };
  },
  created() {
    this.title = sessionStorage.getItem("a_title") || "公厕智能消毒及卫生监测系统";
    this.getDate();
    this.timer = setInterval(() => {
      this.getDate();
    }, 666);
  },

  methods: {
    getDate() {
      const timestamp = new Date().getTime();
      this.nowDate = formatDate(timestamp, "yyyy年MM月dd日 hh:mm");
      const dayOfWeek = new Date().getDay();
      const days = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      this.week = days[dayOfWeek];
    },
    // 时间格式过滤加0
    appendZero(obj) {
      if (obj < 10) {
        return "0" + obj;
      } else {
        return obj;
      }
    },
    back() {
      localStorage.removeItem(this.LoginInfoKey)
      this.$router.push("contorlpage");
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.login-top-m .title {
  position: absolute;
  left: 50%;
  top: 18px;
  transform: translateX(-50%);
  font-size: 32px;
  font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  font-weight: normal;
  color: #08f4da;
  line-height: 58px;
}
img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 94px;
}

.login-top-m .title-m {
  font-size: 38px;
}

/* .login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#011728, #142C3B, #011728);
  padding-top: 25px;
} */

.login-top {
  width: 100%;
  height: 76px;
  position: relative;
  box-sizing: border-box;
}

.login-top-l {
  position: absolute;
  top: 18px;
  left: 40px;
}

.time1 {
  font-size: 18px;
  font-family: FZLTTHK--GBK1-0, FZLTTHK--GBK1;
  font-weight: normal;
  color: #ffffff;
}

.time2 {
  font-size: 18px;
  font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
  font-weight: normal;
  color: #ffffff;
}

.login-top-m {
  width: 1528px;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* background: url(../assets/img/logintop.png) no-repeat;
  background-size: 100% 100%; */
}

.login-top-r {
  position: absolute;
  right: 60px;
  top: 18px;
  font-size: 18px;
}
.login-top-r.hasback {
  right: 180px;
}

.hasback {
  right: 140px;
}

.showback {
  width: 112px;
  height: 56px;
  position: absolute;
  box-sizing: border-box;
  right: 23px;
  top: 0px;
  background: url(../assets/img/back.png) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.login-mid {
  width: 551px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
